import { apiInstance } from "../../instances/apiInstance"
import { apiInstanceV1 } from "../../instances/apiInstanceV1"

import {
    Catalog,
    CreateCatalogRequest,
    CreateCatalogResponse,
    GetCatalogsResponse,
    SharedCatalogResponse,
    UpdateCatalogRequest
} from "../../../models/catalog"
import {
    CreateCategoryRequest,
    CreateCategoryResponse,
    GetCategoriesResponse,
    GetRelatedCategoriesRequest,
    GetRelatedCategoriesResponse,
    MoveCategoryRequest,
    MoveCategoryResponse
} from "../../../models/category"
import {
    Article,
    GetAllPublishActualArticlesInCatalogRequest,
    GetActualArticleFrontendResponse,
    GetArticleResponse,
    GetMarkedMessagesResponse,
    GetMessageMarkupRequest,
    SearchArticlesRequest,
    SearchArticlesResponse,
    ShareArticleResponse,
    UpdateActualArticlePermissionsRequest,
    UpdateArticleRequest,
    UpdateScenarioRequest,
    WithVersionId,
    GetPublishArticleTitlesResponse,
    GetPublishArticleTitlesRequest
} from "../../../models/article"
import {
    FavoriteArticlesBlockRequest,
    FavoriteArticlesBlockResponse,
    FavoriteArticlesBlockExpandRequest,
    FavoriteArticlesBlockMoveRequest
} from "../../../models/articleFavoriteBlock"
import { GetArticleTypesResponse, IntentRecordType } from "../../../models/articleType"
import { ArticleUserView } from "../../../models/articleUserViews"
import { GetShareForActualEntityResponse, SharedActualEntityRequest } from "../../../models/sharing"
import { ArticleCompareRequest, ArticleCompareResponse } from "../../../models/articleCompare"
import {
    ArticleHistoryRequest,
    ArticleHistoryResponse,
    ArticlePreviousVersionResponse
} from "../../../models/ArticleHistory"
import { queryApi } from "../../api"

const knowledgeBaseController = {
    getArticle: (projectId: string, articleCode: string): Promise<GetArticleResponse> => {
        return apiInstance.get(`/${projectId}/articles/${articleCode}`).then(response => response.data)
    },
    searchArticles: (projectId: string, request: SearchArticlesRequest): Promise<SearchArticlesResponse> => {
        return apiInstance.post(`/${projectId}/articles/search`, request).then(response => response.data)
    },
    updateArticle: (projectId: string, articleCode: string, request: UpdateArticleRequest): Promise<string> => {
        return apiInstance.put(`/${projectId}/articles/${articleCode}`, request).then(response => response.data)
    },
    getArticleSharing(projectId: string, articleCode: string): Promise<GetShareForActualEntityResponse> {
        return apiInstance.get(`/${projectId}/articles/${articleCode}/share`).then(response => response.data)
    },
    shareArticle(
        projectId: string,
        articleCode: string,
        request: SharedActualEntityRequest
    ): Promise<ShareArticleResponse> {
        return apiInstance
            .post(`/${projectId}/articles/${articleCode}/share/create`, request)
            .then(response => response.data)
    },
    removeArticleSharing(projectId: string, articleCode: string, request: SharedActualEntityRequest): Promise<void> {
        return apiInstance
            .post(`/${projectId}/articles/${articleCode}/share/delete`, request)
            .then(response => response.data)
    },
    updateArticlePermissions: (
        projectId: string,
        articleCode: string,
        request: UpdateActualArticlePermissionsRequest
    ): Promise<string> => {
        return apiInstance
            .put(`/${projectId}/articles/${articleCode}/permissions`, request)
            .then(response => response.data)
    },
    updateScenario: (projectId: string, articleCode: string, request: UpdateScenarioRequest): Promise<string> => {
        return apiInstance.put(`/${projectId}/scenarios/${articleCode}`, request).then(response => response.data)
    },
    publishArticle: (projectId: string, articleCode: string, request: WithVersionId): Promise<string> => {
        return apiInstance.put(`/${projectId}/articles/${articleCode}/publish`, request).then(response => response.data)
    },
    sendArticleForApproval: (projectId: string, articleCode: string, request: WithVersionId): Promise<string> => {
        return apiInstance
            .put(`/${projectId}/articles/${articleCode}/send_for_approval`, request)
            .then(response => response.data)
    },
    confirmArticleRead: (projectId: string, articleCode: string): Promise<ArticleUserView[]> => {
        return apiInstance.put(`/${projectId}/articles/${articleCode}/confirm_read`).then(response => response.data)
    },
    getDefaultArticleByRole: (projectId: string): Promise<GetActualArticleFrontendResponse> => {
        return apiInstance.post(`/${projectId}/articles/default_by_role`).then(response => response.data)
    },
    getCatalogs: (projectId: string): Promise<GetCatalogsResponse> => {
        return apiInstance.get(`/${projectId}/catalogs`).then(response => response.data)
    },
    getCategories: (projectId: string, catalogCode: string, parentId: string): Promise<GetCategoriesResponse> => {
        return apiInstance
            .get(`/${projectId}/catalogs/${catalogCode}/categories/${parentId}/categories`)
            .then(response => response.data)
    },
    getAllCategories: (projectId: string, catalogCode: string, parentId: string): Promise<GetCategoriesResponse> => {
        return apiInstance
            .get(`/${projectId}/catalogs/${catalogCode}/categories/${parentId}/all-categories`)
            .then(response => response.data)
    },
    getRelatedCategories: (
        projectId: string,
        request: GetRelatedCategoriesRequest
    ): Promise<GetRelatedCategoriesResponse> => {
        return apiInstance.post(`/${projectId}/catalogs/categories/related`, request).then(response => response.data)
    },
    createCategory: (
        projectId: string,
        catalogCode: string,
        request: CreateCategoryRequest
    ): Promise<CreateCategoryResponse> => {
        return apiInstance
            .post(`/${projectId}/catalogs/${catalogCode}/categories`, request)
            .then(response => response.data)
    },
    moveCategory: (projectId: string, request: MoveCategoryRequest): Promise<MoveCategoryResponse> => {
        return apiInstance.post(`/${projectId}/catalogs/categories/move`, request).then(response => response.data)
    },
    createCatalog: (projectId: string, request: CreateCatalogRequest): Promise<CreateCatalogResponse> => {
        return apiInstance.post(`/${projectId}/catalogs`, request).then(response => response.data)
    },
    updateCatalog: (projectId: string, catalogCode: string, request: UpdateCatalogRequest): Promise<Catalog> => {
        return apiInstance.put(`/${projectId}/catalogs/${catalogCode}`, request).then(response => response.data)
    },
    deleteCatalog: (projectId: string, catalogCode: string): Promise<string> => {
        return apiInstance.delete(`/${projectId}/catalogs/${catalogCode}`).then(response => response.data)
    },
    getCatalogSharing(projectId: string, catalogCode: string): Promise<GetShareForActualEntityResponse> {
        return apiInstance.get(`/${projectId}/catalogs/${catalogCode}/share`).then(response => response.data)
    },
    shareCatalog(projectId: string, catalogCode: string): Promise<SharedCatalogResponse> {
        return apiInstance.post(`/${projectId}/catalogs/${catalogCode}/share/create`).then(response => response.data)
    },
    removeCatalogSharing(projectId: string, catalogCode: string): Promise<void> {
        return apiInstance.post(`/${projectId}/catalogs/${catalogCode}/share/delete`).then(response => response.data)
    },
    getArticleTypes: (projectId: string): Promise<GetArticleTypesResponse> => {
        return apiInstance.get(`/${projectId}/article_types`).then(response => response.data)
    },
    createArticleType: (articleType: IntentRecordType): Promise<IntentRecordType> =>
        apiInstanceV1.put("knowledgebase/intent_record_type", articleType).then(response => response.data),
    updateArticleType: (articleType: IntentRecordType): Promise<IntentRecordType> =>
        apiInstanceV1.post("knowledgebase/intent_record_type", articleType).then(response => response.data),
    deleteArticleType: (id: string): Promise<void> =>
        apiInstanceV1
            .delete("knowledgebase/intent_record_type", {
                params: { id }
            })
            .then(response => response.data),
    refreshArticle: (projectId: string, articleCode: string): Promise<string> => {
        return apiInstance.post(`/${projectId}/articles/${articleCode}/refresh`).then(response => response.data)
    },
    removeArticle: (projectId: string, articleCode: string, request: WithVersionId): Promise<string> => {
        return apiInstance.post(`/${projectId}/articles/${articleCode}/remove`, request).then(response => response.data)
    },
    setUnpublishedStatusFortArticle: (
        projectId: string,
        articleCode: string,
        request: WithVersionId
    ): Promise<string> => {
        return apiInstance
            .post(`/${projectId}/articles/${articleCode}/set_unpublished`, request)
            .then(response => response.data)
    },
    getMessageMarkup(markupId: string, request: GetMessageMarkupRequest): Promise<GetMarkedMessagesResponse> {
        return apiInstanceV1
            .post(`marked_messages/simple/page`, request, {
                params: { markup_id: markupId }
            })
            .then(response => response.data)
    },
    removeAllMessageMarkup(markupId: string): Promise<void> {
        return apiInstanceV1.get("marked_messages/all/remove_markup", {
            params: { markup_id: markupId }
        })
    },
    removeMessageMarkup(markupId: string, messageIds: string[]): Promise<void> {
        return apiInstanceV1.post("marked_messages/remove_markup", messageIds, { params: { markup_id: markupId } })
    },
    getAllPublishTitles(
        projectId: string,
        request: GetPublishArticleTitlesRequest
    ): Promise<GetPublishArticleTitlesResponse> {
        return apiInstance
            .post(`/${projectId}/articles/get_all_publish_titles`, request)
            .then(response => response.data)
    },
    setArticleFavorite: (
        projectId: string,
        articleCode: string,
        request: FavoriteArticlesBlockRequest
    ): Promise<FavoriteArticlesBlockResponse> => {
        return apiInstance
            .post(`${projectId}/articles/${articleCode}/favorites`, request)
            .then(response => response.data)
    },
    getFavoriteArticlesBlock: (projectId: string): Promise<FavoriteArticlesBlockResponse> => {
        return apiInstance.get(`/${projectId}/favorite-block`).then(response => response.data)
    },
    expandFavoriteBlock: (
        projectId: string,
        request: FavoriteArticlesBlockExpandRequest
    ): Promise<FavoriteArticlesBlockResponse> => {
        return apiInstance.post(`${projectId}/favorite-block/expand`, request).then(response => response.data)
    },
    moveArticleOfFavoriteBlock: (
        projectId: string,
        request: FavoriteArticlesBlockMoveRequest
    ): Promise<FavoriteArticlesBlockResponse> => {
        return apiInstance.post(`${projectId}/articles/favorites/drag`, request).then(response => response.data)
    },
    getArticleCompare: (projectId: string, request: ArticleCompareRequest): Promise<ArticleCompareResponse> => {
        return apiInstance.post(`${projectId}/articles/compare`, request).then(response => response.data)
    },
    getArticleHistory: (arg: ArticleHistoryRequest): Promise<ArticleHistoryResponse> => {
        const [projectId, articleCode] = arg
        return apiInstance.get(`${projectId}/articles/${articleCode}/history`).then(response => response.data)
    },
    getArticlePreviousVersion: (projectId: string, articleId: string): Promise<ArticlePreviousVersionResponse> => {
        return apiInstance.get(`${projectId}/articles/by_id/${articleId}`).then(response => response.data)
    },
    createDraftFrom: (
        projectId: string,
        articleCode: string,
        articleId: string
    ): Promise<ArticlePreviousVersionResponse> => {
        return apiInstance
            .post(`${projectId}/articles/${articleCode}/create_draft_from?articleId=${articleId}`)
            .then(response => response.data)
    }
}

export default knowledgeBaseController

export const knowledgeBaseApi = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getAllPublishInCatalog: build.mutation<
                Article[],
                { projectId: string; body: GetAllPublishActualArticlesInCatalogRequest }
            >({
                query: ({ projectId, body }) => ({
                    url: `${projectId}/articles/get_all_publish_in_catalog`,
                    method: "POST",
                    body
                })
            }),
            importFile: build.mutation<void, { body: FormData }>({
                query: ({ body }) => ({
                    url: `import-document`,
                    method: "POST",
                    body: body
                })
            })
        }
    }
})

export const { useGetAllPublishInCatalogMutation, useImportFileMutation } = knowledgeBaseApi
