import React from "react"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import styles from "./SettingsGroupContent.module.scss"
import { Button } from "react-bootstrap"
import { testId } from "../../utility/tests/testId"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTrash } from "@fortawesome/pro-light-svg-icons"
import { formTranslation } from "../../locales/form"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { Settings } from "../../types/settings"

export interface SettingsGroupContentProps<T> {
    values: Settings<T>
    getLabel: (value: T) => string
    disabled?: boolean
    name: string
}

function SettingsGroupContent<T>(props: SettingsGroupContentProps<T>) {
    const { values, getLabel, disabled, name } = props

    const { t } = useTranslation()
    const { setFieldValue } = useFormikContext()

    const keys = Object.keys(values)

    const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        keys.forEach(v => setFieldValue(`${name}.${v}.isChecked`, true))
    }

    const handleClearAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        keys.forEach(v => setFieldValue(`${name}.${v}.isChecked`, false))
    }

    return (
        <>
            <div className={styles.settingsGroupContent__buttons}>
                <Button
                    variant="light"
                    onClick={handleSelectAll}
                    className={styles.settingsGroupContent__btn}
                    data-testid={testId.selectAll}
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon={faCheck} className={styles.settingsGroupContent__btnIcon} />
                    {t(formTranslation.selectAll)}
                </Button>
                <Button
                    variant="light"
                    onClick={handleClearAll}
                    className={styles.settingsGroupContent__btn}
                    data-testid={testId.clearAll}
                    disabled={disabled}
                >
                    <FontAwesomeIcon icon={faTrash} className={styles.settingsGroupContent__btnIcon} />
                    {t(formTranslation.clearAll)}
                </Button>
            </div>
            <div className={styles.settingsGroupContent__inputsContainer}>
                {Object.entries(values).map(([k, v]) => (
                    <div className={styles.settingsGroupContent__checkBoxInput} key={k}>
                        <CheckBoxValidatableInput
                            id={"form" + k}
                            disabled={disabled}
                            name={`${name}.${k}.isChecked`}
                            label={getLabel(v.value)}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default SettingsGroupContent
