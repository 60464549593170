export const imageLazy = (path: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()
        image.src = path

        image.onload = () => resolve(image)
        image.onerror = () => reject(new Error(`Image load error for ${path}`))
    })

export type TSuspensableImageStatus = "pending" | "success" | "error"

export const suspensableImageTrigger = (path: string) => {
    let status: TSuspensableImageStatus = "pending"
    let result: HTMLImageElement | Error

    const thenableTrigger = imageLazy(path).then(
        (image: HTMLImageElement) => {
            status = "success"
            result = image
        },
        (e: Error) => {
            status = "error"
            result = e
        }
    )

    return {
        read() {
            switch (status) {
                case "pending":
                    throw thenableTrigger
                case "error":
                    throw result
                case "success":
                    return result
            }
        }
    }
}

export type TSuspensableImageTriggerRet = ReturnType<typeof suspensableImageTrigger>

const suspensableImagesCache = new Map<string, TSuspensableImageTriggerRet>()

export const SuspensableImage: React.FC<
    React.ImgHTMLAttributes<HTMLImageElement> & {
        src: string
    }
> = props => {
    try {
        let triggerInstance = suspensableImagesCache.get(props.src)

        if (!triggerInstance) {
            triggerInstance = suspensableImageTrigger(props.src)
            suspensableImagesCache.set(props.src, triggerInstance)
        }

        triggerInstance.read()

        return <img {...props} alt="" />
    } catch (e) {
        console.error(`Error with loading image on ${props.src}`, e)
        return null
    }
}
