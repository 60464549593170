import cn from "classnames"
import { memo, useEffect, useRef, useState } from "react"
import { useGetNewslineQuery } from "../../api/controllers/knowledgeBase/newsline"
import { useIsTabActive } from "../../hooks/useIsTabActive"
import { useAppSelector } from "../../store/hooks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { testId } from "../../utility/tests/testId"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { BounceIndicator } from "./components/BounceIndicator/BounceIndicator"
import { NewslineItem } from "./components/NewslineItem/NewslineItem"
import { NewsList } from "./components/NewsList/NewsList"
import styles from "./Newsline.module.scss"

interface NewslineProps {
    className?: string
}

const popperConfig = { modifiers: [{ name: "offset", options: { offset: [0, 0] } }] }

export const Newsline = memo(({ className }: NewslineProps) => {
    const [listKey, setListKey] = useState(0)
    const projectId = useAppSelector(selectCurrentProjectId)
    const newslineRef = useRef<HTMLDivElement>(null)
    const isTabActive = useIsTabActive()
    const { data: news } = useGetNewslineQuery(String(projectId), {
        skip: !projectId,
        pollingInterval: 10000
    })

    useEffect(() => {
        if (news) {
            setListKey(prevKey => prevKey + 1)
        }
    }, [news])

    if (news && news.length) {
        return (
            <PopoverTrigger
                id="newsline-list"
                className={styles.popover}
                content={<NewsList list={news} />}
                trigger="click"
                placement="bottom"
                popperConfig={popperConfig}
                container={() => newslineRef.current}
                rootClose
            >
                <div className={cn(styles.newsline, className)} ref={newslineRef} data-testid={testId.newsline}>
                    <BounceIndicator />
                    <ul className={styles.news} key={listKey}>
                        {news.map((newsItem, i) => (
                            <NewslineItem
                                key={newsItem.ArticleCode}
                                item={newsItem}
                                index={i}
                                total={news.length}
                                isTabActive={isTabActive}
                            />
                        ))}
                    </ul>
                </div>
            </PopoverTrigger>
        )
    }

    return null
})
