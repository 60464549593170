import { imageLazy } from "../../helpers/image"
import { ThemeSettings } from "../../models/config"
import { ThemingEngine } from "./ThemingEngine"
import { ColorSchema } from "../../models/projectSettings"

export class ThemingInjectorScenario extends ThemingEngine {
    public static FAVICON_SIZE = 32

    public static FAVICON_MARKED_FILL_COLOR = "#0067AD"

    public static async installFont(brandingRules: ThemeSettings) {
        const { fontName, fontPath, fontPrefetch } = brandingRules

        const isFontExist = Array.from(document.fonts).some(font => font.family === fontName)

        if (fontName && !isFontExist) {
            if (fontPath) {
                await this.installFontFace(fontName, fontPath, fontPrefetch)
            } else {
                await this.installGoogleFont(fontName)
            }
        }
    }

    public static async changeTitle(brandingRules: ThemeSettings) {
        const { instanceTitle } = brandingRules

        if (instanceTitle && instanceTitle !== document.title) {
            await this.swapTitle(instanceTitle)
        }
    }

    public static async installFavicon(brandingRules: ThemeSettings, marked?: boolean) {
        try {
            const faviconPath = brandingRules.faviconPath ?? `${process.env.PUBLIC_URL}/favicon.png`

            if (marked) {
                const image = await imageLazy(faviconPath)

                const canvas = document.createElement("canvas")
                canvas.width = canvas.height = this.FAVICON_SIZE

                const context = canvas.getContext("2d")

                if (context) {
                    context.drawImage(image, 0, 0, this.FAVICON_SIZE, this.FAVICON_SIZE)
                    context.beginPath()

                    context.arc(
                        canvas.width - this.FAVICON_SIZE / 6,
                        canvas.height - this.FAVICON_SIZE / 6,
                        this.FAVICON_SIZE / 5,
                        0,
                        2 * Math.PI
                    )

                    context.fillStyle = this.FAVICON_MARKED_FILL_COLOR
                    context.fill()

                    await ThemingEngine.swapFavicon(canvas.toDataURL("image/png"))
                }
            } else {
                await ThemingEngine.swapFavicon(faviconPath)
            }
        } catch (e) {
            console.log("Error while installing favicon", e)
        }
    }
    public static renderSvgIcon(path: string) {
        if (!path.endsWith(".svg")) {
            return
        }

        return this.renderImage(path)
    }

    public static initializeColors(colorSchema?: ColorSchema) {
        if (colorSchema) {
            this.setThemeColors(colorSchema)
        }
    }

    public static run(brandingRules: ThemeSettings) {
        return Promise.all([
            this.installFont(brandingRules),
            this.installFavicon(brandingRules),
            this.changeTitle(brandingRules)
        ])
    }
}

export { ThemingEngine }
