import { DialogTopicArray } from "./dialog"
import { FilledSlot, MessageSlots } from "../slot"

export type MessageSender = {
    Id: string
}

export type Message = {
    MsgId: string
    MsgType: MessageType
    Direction: MessageDirection
    IdInChannel: string
    ChannelId?: string
    ReplyToMessage?: Message
    Attachment?: MessageAttachment
    Attachments?: MessageAttachment[]
    Sticker?: Sticker
    Text: string
    Actions: Action[]
    ClientId: number
    OperatorId?: number
    OperatorName: string
    CallCenterOperatorId?: string
    DialogID: string
    UserPic?: string
    UserName?: string
    Context: string[][]
    IntentQaId?: string
    Flags: MessageFlags[]
    IsServiceMessage: boolean
    IsClientServiceMessage: boolean
    Added: string
    Meta?: Record<string, string>
    TextType: MessageTextType
    ParentMessageId?: string
    EditType?: EditEnum
}

export interface MessageOperator {
    Id: string
    Name: string
}

export enum MessageTextType {
    Text = "Text",
    Markdown = "Markdown"
}

export enum EditEnum {
    Deleted = "Deleted",
    Edited = "Edited"
}

export interface UpdatedMessage {
    Id: string
    MessageType: MessageType
    Direction: MessageDirection
    Sender: MessageSender
    ChannelUserId?: string
    ChannelId: string
    Text: string
    Sticker?: Sticker
    Actions: Action[]
    Attachments: MessageAttachment[]
    DialogId: string
    AddedDate: number
    ReplyToMessage?: UpdatedMessage
    Context: string[][]
    Meta: Record<string, string>
    Status: MessageStatus
    TextType: MessageTextType
    EditType?: EditEnum
    OmniUserId?: string
}

export type PinnedMessages = {
    Messages: UpdatedMessage[]
}

export type HubPinnedMessages = {
    ActiveDialogId: string
    Messages?: UpdatedMessage[]
}

export enum MessageType {
    Initial = 0,
    Message = 1,
    SentConfirmation = 2,
    ReferenceCreated = 3,
    ReadConfirmation = 4,
    FailedConfirmation = 5,
    UpdateDialogProgram = 6,
    WritingStop = 7,
    Deleted = 8,
    PhoneNumber = 9,
    Location = 10,
    ReceivedByMediator = 11,
    ReceivedByOperator = 12,
    OperatorIsTyping = 13,
    OperatorStoppedTyping = 14,
    UpdateDialogScore = 15,
    FinishDialog = 16,
    CloseDialogIntention = 17,
    ConnectedOperator = 18,
    DialogFinished = 19,
    AutoGreeting = 20,
    UpdateDialogNegativeReason = 21,
    UpdateUserData = 22,
    ClientHold = 23,
    UpdateDialogUsefulness = 24,
    MessageEdited = 31,
    MessageDeleted = 32,
    CobrowsingRequest = 100,
    CobrowsingStarted = 101,
    CobrowsingDenied = 102,
    CobrowsingStateUpdate = 103,
    CobrowsingFinished = 104
}

export enum MessageDirection {
    Incoming = 0,
    Outgoing = 1
}

export interface MessageAttachment {
    Url: string
    ContentType: string
    Name: string
    Size: number
}

export interface Sticker {
    sticker_id: string
    sticker_url?: string
    animation_type: StickerAnimationType
}

enum StickerAnimationType {
    NoAnimation,
    Lottie
}

enum MessageFlags {
    Unknown = -1,
    Sent = 0,
    Delivered = 1,
    Read = 2,
    Failed = 3,
    Deleted = 4,
    Phone = 5,
    Location = 6,
    AutoGreeting = 7
}

export interface Action {
    action_id: string
    action_text: string
}

// здесь стоит UpdatedMessage
// но на беке есть некий ApiMessage, который отличается
// при создании реплаев это нужно учесть
// https://gitlab.crafttalk.ru/aibotdev/core/opbot/-/blob/develop/src/opbot/Workplace/V2/Dto/Messages.fs#L42
export interface SendMessageRequest {
    OmniUserId: string
    ChannelId: string
    StartNewDialog: boolean
    ReplyToMessage?: UpdatedMessage
    Attachments: MessageAttachment[]
    Sticker?: Sticker
    Text: string
    Actions: Action[]
    Meta: Record<string, string>
    Slots: MessageSlots
}

export interface SendIntentRequest {
    ProjectId: string
    OmniUserId?: string
    ChannelUserId?: string
    ChannelId: string
    StartNewDialog: boolean
    IntentId: string
    DoDetachOperator: boolean
    UpdatedSlots?: FilledSlot[]
    Meta?: MessageMeta
}

export interface ISendFinishRequest {
    OmniUserId: string
    ChannelId: string
    ProjectId: string
    Topics?: DialogTopicArray[]
}

export interface MergedMessageRequest {
    projectId: string
    message: SendMessageRequest
    body: GetUpdatedMessagesRequest
}

export interface ScoreMessage {
    Id: string
    Score: string
}

export interface IdMessage {
    Id: string
}

export type DialogFinishScore = "1" | "2" | "3" | "4" | "5"

export interface DialogFinishMessage {
    Id: string
    DialogId: string
    Timestamp: number
    Score?: DialogFinishScore
}

export interface GetMessagesResponse {
    [dialogId: string]: Message[]
}

export interface GetUpdatedMessagesRequest {
    OmniUserId: string
    Count: number
    StartTime: number
    FromTodayDialogs: boolean
    ProjectId: string
}

export interface ApiMessage {
    Case: "Message"
    Fields: UpdatedMessage
}

export const isDeletedMessage = (message: ApiMessagesDU): message is ApiDeletedMessage => {
    return message.Case === "DeletedByOperator" && typeof message.Fields === "object"
}

export const isEditedMessage = (message: ApiMessagesDU): message is ApiEditMessage => {
    return (
        message.Case === "EditedByOperator" && typeof message.Fields === "object" && "EditedMessageId" in message.Fields
    )
}

export enum MessageCase {
    Message = "Message",
    Score = "Score",
    DialogFinish = "DialogFinish",
    DeletedByOperator = "DeletedByOperator",
    EditedByOperator = "EditedByOperator",
    OperatorRerouteDialogConfirmation = "OperatorRerouteDialogConfirmation",
    OperatorRerouteDialog = "OperatorRerouteDialog"
}

export interface ApiScore {
    Case: MessageCase.Score
    Fields: ScoreMessage
}

export interface ApiDialogFinish {
    Case: MessageCase.DialogFinish
    Fields: DialogFinishMessage
}

export type ApiDeletedMessage = {
    Case: MessageCase.DeletedByOperator
    Fields: DeletedMessage
}

export interface ApiDialogOperatorRerouteConfirmation {
    Case: MessageCase.OperatorRerouteDialog
    Fields: DialogOperatorRerouteConfirmation
}

export interface EditedMessage extends UpdatedMessage {
    EditedMessageId: string
}

export type ApiEditMessage = {
    Case: MessageCase.EditedByOperator
    Fields: EditedMessage
}

export type DeletedMessage = {
    DeletedMessageId: string
    DialogId: string
    Id: string
    Timestamp: number
}

export type DialogStartMessage = {
    Id: string
    DialogId: string
    Timestamp: number
    ChannelId: string
    Direction: MessageDirection
}

export type DialogOperatorRerouteConfirmation = {
    Id: string
    DialogId: string
    Timestamp: number
    IsSupervisor: boolean
    From: {
        OperatorId: string
        QueueName: string
    }
    To: {
        OperatorId: string
        QueueName: string
    }
}

export type ApiMessagesDU =
    | ApiMessage
    | ApiScore
    | ApiDialogFinish
    | ApiDeletedMessage
    | ApiEditMessage
    | ApiDialogOperatorRerouteConfirmation

export type EditedElement = {
    text: string
    attachments: MessageAttachment[]
    sticker?: Sticker
}

export type TMessageWithEditHistory = ApiMessagesDU & { EditedHistory?: EditedElement[] }

export interface GetUpdatedMessagesResponse {
    Messages: TMessageWithEditHistory[]
    HasMore: boolean
}

export enum SenderRole {
    Operator,
    Client
}

export enum SendMessageStatuses {
    SentToExistingDialog = 1,
    NewDialogWillBeStarted = 2,
    HasActiveDialogAlready = 3,
    DialogDoesNotExist = 4
}

export type SendMessageResponse = {
    Status: SendMessageStatuses
    MessageId: string
}

export interface GetMessageSenderDataRequest {
    SenderRole: SenderRole
    // A client's omniUserId or operator's userId
    Id: string
}

export enum MessageStatus {
    Unknown = 0,
    Sent = 1,
    ReceivedByMediator = 2,
    Delivered = 3,
    Read = 4
}

export interface StartOutgoingDialogMessageRequest {
    CustomerId: string
    Text: string
    ChannelUserId: string
    ChannelId: string
    FilledSlots: MessageSlots
    Attachments: MessageAttachment[]
    Meta: MessageMeta
}

export const isApiMessage = (check: unknown): check is ApiMessage => {
    return (check as ApiMessage).Fields !== undefined && (check as ApiMessage).Case === "Message"
}

export interface EmailValues {
    to: string
    from: string
    subject: string
    cc: string
    bcc: string
    isForwarding: boolean
}

export type MessageMeta = Record<string, string>

export interface MessageInputValue {
    text: string
    email: EmailValues | undefined
}
